<template>
  <div class="asset-change-detail">
    <a-spin :spinning="loading">
      <div class="content flex-ct between" style="min-height: 0">
        <a-tabs default-active-key="BaseInfo" @change="changeTabs">
          <a-tab-pane key="BaseInfo" :tab="$t(`基本信息`)"></a-tab-pane>
          <a-tab-pane key="ChangeDetailsInfo" :tab="$t(`变更详情`)"></a-tab-pane>
          <a-tab-pane key="Attachment" :tab="$t(`附件`)"></a-tab-pane>
        </a-tabs>
        <div class="flex-ct" v-show="!loading">
          <OperateBtn
            v-if="formData.orderStatus === 'WAIT_CHECK'"
            api="operateAssetChange"
            :text="$t('审核')"
            :params="{
              action: 'CHECK',
              idList: [id],
            }"
            @success="init"
          />
          <OperateBtn
            class="ml10"
            v-if="isDetail && ['DRAFT', 'WAIT_CHECK', 'WAIT_PAY'].includes(formData.orderStatus)"
            api="operateAssetChange"
            type="danger"
            :text="$t('作废')"
            :params="{
              action: 'DELETE',
              idList: [id],
            }"
            @success="init"
          />
          <VoucherBtn
            class="ml10"
            v-if="isDetail && !['DRAFT', 'WAIT_CHECK', 'CANCELLED'].includes(formData.status)"
            :docType="docType"
            :selectedRows="[formData]"
            @close="init"
          />
        </div>
      </div>
      <div class="content-overflow">
        <section class="left-div fixContent" :style="{ height: scrollHeight }">
          <a-form-model ref="ruleForm" :model="formData">
            <ModalBox id="BaseInfo" :title="$t('基本信息')">
              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-model-item :label="$t(`单据编号`)">
                    <a-input disabled v-model="formData.orderCode"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`货主组织`)" required>
                    <CommonSelect
                      :isBu="true"
                      :list="$store.state.settlementBuList"
                      :placeholder="$t('货主组织')"
                      :disabled="isDisabled"
                      :code.sync="formData.ownerBusinessUnitCode"
                      :name.sync="formData.ownerBusinessUnitName"
                      @change="getCurrentAccountingPeriod"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`变更项目`)" required>
                    <a-input
                      class="ant-input"
                      readOnly
                      :value="detailItemName"
                      :placeholder="$t('变更项目')"
                      :disabled="isDisabled"
                      @click="showChangeItem"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`记账日期`)" required>
                    <DatePicker
                      :needhms="false"
                      style="background-color: #ffffff; width: 100%"
                      :init-date-time="formData.accountingDate"
                      :disabledDate="disabledDate"
                      @choose="chooseAccountingDate"
                      :disabled="isDisabled"
                      :placehold="$t(`记账日期`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`备注`)">
                    <a-input
                      v-model="formData.remark"
                      :placeholder="$t('备注')"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`状态`)">
                    <a-input
                      :value="assetOperateStatusMapping[formData.orderStatus]"
                      :disabled="true"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
            </ModalBox>
            <ModalBox id="ChangeDetailsInfo" :title="$t('变更详情')">
              <template v-slot:header>
                <div class="option-btns pr20" v-if="!isDisabled">
                  <span class="text-link fz16" @click="showAssetCardModal">
                    {{ $t('增行') }}
                  </span>
                  <span class="text-link fz16 ml10" @click="deleteAssetCard">
                    {{ $t('删行') }}
                  </span>
                </div>
              </template>
              <a-table
                :rowKey="(record, index) => index"
                :row-selection="{
                  selectedRowKeys: selectedRowKeys2,
                  onChange: onSelectChange2,
                }"
                size="small"
                :columns="detailColumns"
                :dataSource="formData.detailList"
                :scroll="{ x: tableWidth }"
                :pagination="false"
              >
                <template slot="changeReason" slot-scope="text, record">
                  <a-input v-model="record.changeReason" :disabled="isDisabled" />
                </template>
                <template
                  v-for="col in getBAList(inputScopedSlots)"
                  v-slot:[col]="text, record, index"
                >
                  <div :key="col" v-if="isPriceInput(col)">
                    <PriceInput
                      :showTxt="col.includes('Before')"
                      :key="index"
                      :value.sync="record[col]"
                      :disabled="isDisabled"
                    />
                  </div>
                  <div :key="col" v-else>
                    <a-input :key="index" v-model="record[col]" :disabled="isDisabled" />
                  </div>
                </template>
                <template slot="depreciationMethodCodeAfterValue" slot-scope="text, record">
                  <CommonQuerySelect
                    :placeholder="$t('折旧方法')"
                    api="getDepreciationMethodList"
                    :disabled="isDisabled"
                    :code.sync="record.depreciationMethodCodeAfterValue"
                    :name.sync="record.depreciationMethodCodeAfterName"
                    valueName="depreciation_method_code"
                    labelName="depreciation_method_name"
                  />
                </template>
                <template slot="departmentCodeAfterValue" slot-scope="text, record">
                  <CommonQuerySelect
                    api="getDepartmentList"
                    :placeholder="$t('使用部门')"
                    :disabled="isDisabled"
                    :code.sync="record.departmentCodeAfterValue"
                    :name.sync="record.departmentCodeAfterValue"
                  />
                </template>
                <template slot="startUseDateAfterValue" slot-scope="text, record">
                  <DatePicker
                    :needhms="false"
                    style="background-color: #ffffff; width: 100%"
                    :init-date-time="record.startUseDate"
                    :disabled="isDisabled"
                    :placehold="$t(`开始使用日期`)"
                    @choose="
                      (data) => {
                        record.startUseDateAfterValue = data.data
                      }
                    "
                  />
                </template>
                <template slot="storeLocationAfterValue" slot-scope="text, record">
                  <CommonQuerySelect
                    :placeholder="$t('存放地点')"
                    api="getStoreLocationList"
                    :disabled="isDisabled"
                    :code.sync="record.storeLocationAfterValue"
                    :name.sync="record.storeLocationAfterName"
                    valueName="location_code"
                    labelName="location_name"
                  />
                </template>
                <template slot="supplierCodeAfterValue" slot-scope="text, record">
                  <CommonQuerySelect
                    api="queryPurchaseSupplierList"
                    :params="{
                      status: 1,
                    }"
                    :placeholder="$t(`供应商`)"
                    :code.sync="record.supplierCodeAfterValue"
                    :name.sync="record.supplierCodeAfterName"
                    :disabled="isDisabled"
                    valueName="supplier_code"
                    labelName="supplier_name"
                  />
                </template>
                <template slot="unitAfterValue" slot-scope="text, record">
                  <CommonQuerySelect
                    :placeholder="$t('计量单位')"
                    api="getAssetUnitList"
                    :disabled="isDisabled"
                    :code.sync="record.unitAfterValue"
                    :name.sync="record.unitAfterName"
                    valueName="asset_unit_code"
                    labelName="asset_unit_name"
                  />
                </template>
                <template slot="useStatusAfterValue" slot-scope="text, record">
                  <CommonQuerySelect
                    :placeholder="$t('使用状态')"
                    api="getAssetUseStatusList"
                    :disabled="isDisabled"
                    :code.sync="record.useStatusAfterValue"
                    :name.sync="record.useStatusAfterName"
                    valueName="use_status_code"
                    labelName="use_status_name"
                  />
                </template>
              </a-table>
            </ModalBox>
            <ModalBox id="Attachment" :title="$t('附件')">
              <div class="attachment-list">
                <Attachment ref="AttachmentRef" :list="fileList" :disabled="isDisabled" />
              </div>
            </ModalBox>
          </a-form-model>
        </section>
        <OperationLog
          ref="OperationLogRef"
          :scrollHeight="scrollHeight"
          :actionSn="sn"
          :type="docType"
          v-if="isDetail"
        />
      </div>
      <BottomBtns
        :isEdit.sync="isEdit"
        :isDetail="isDetail"
        :draftLoading="draftLoading"
        :saveLoading="saveLoading"
        :status="formData.orderStatus"
        @submit="onSubmit"
        @cancel="getDetailInfo"
        @back="backForm"
      />
    </a-spin>
    <a-modal
      width="800px"
      :title="$t('变更项目')"
      v-model="visible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <CommonTable
        :columns="columns"
        :dataSource="dataSource"
        :showPagination="false"
        :showSelect="true"
        :selectedRowKeys.sync="selectedRowKeys"
        :selectedRows.sync="selectedRows"
        :loading="tableLoading"
        rowKey="id"
        :showSetting="false"
        @select="onSelectChange"
      ></CommonTable>
    </a-modal>
    <SelectAssetCardModal
      ref="AssetCardModal"
      assetOperateCode="CHANGE"
      :hasSelected="hasSelected"
      :ownerBusinessUnitCode="formData.ownerBusinessUnitCode"
      @ok="handleAssetCardOk"
    ></SelectAssetCardModal>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import DatePicker from '@component/datePicker'
import BottomBtns from '@component/bottomBtns'
import OperationLog from '@component/operationLog'
import Attachment from '@component/attachment'
import SelectAssetCardModal from '@component/selectAssetCardModal'
import deepClone from '@/common/deepClone'
import debounce from '@/common/debounce'
import { convertKeysToCamelCase, goBack } from '@/common'
export default {
  name: 'AssetChangeDetail',
  components: { DatePicker, BottomBtns, OperationLog, SelectAssetCardModal, Attachment },
  data() {
    return {
      docType: 'AssetChange',
      id: undefined,
      sn: undefined,
      isDetail: false, //是否是详情页
      isEdit: false, //是否编辑状态
      formData: {
        orderStatus: 'DRAFT',
        ownerBusinessUnitCode: undefined,
        detailList: [],
        changeItemList: [],
      },
      loading: false,
      draftLoading: false,
      saveLoading: false,
      isReturn: true,
      visible: false,
      tableLoading: false,
      dataSource: [],
      selectedRowKeys: [],
      selectedRowKeys2: [],
      selectedRows: [],

      detailItemList: [],

      priceInputScopedSlots: ['originalValue', 'netResidualValue', 'inputTax'],

      inputScopedSlots: [
        'originalValue',
        'netResidualValue',
        'inputTax',
        'quantity',
        'assetName',
        // 'depreciationMethodCode',
        // 'departmentCode',
        'spec',
        'expectUsagePeriods',
        // 'startUseDate',
        'remark',
        // 'storeLocation',
        // 'supplierCode',
        // 'unit',
        // 'useStatus',
      ],
      startDate: undefined,
      endDate: undefined,
      fileList: [],
    }
  },
  activated() {
    this.init()
  },
  beforeRouteUpdate(to, from, next) {
    Object.assign(this.$data, this.$options.data())
    this.$nextTick(() => {
      this.init()
    })
    next()
  },
  computed: {
    ...mapGetters(['assetOperateStatusMapping']),
    isDisabled() {
      return this.isDetail && !this.isEdit
    },
    tableWidth() {
      return 1000 + this.formData.changeItemList.length * 300
    },
    scrollHeight() {
      return window.innerHeight - 280 + 'px'
    },
    detailItemName() {
      return this.formData.changeItemList.map((item) => item.itemName).join(';')
    },
    columns() {
      let list = [
        {
          title: this.$t('编码'),
          dataIndex: 'itemCode',
        },
        {
          title: this.$t('名称'),
          dataIndex: 'itemName',
        },
        {
          title: this.$t('影响折旧'),
          dataIndex: 'influenceDepreciation',
          customRender: (text) => (+text ? this.$t('是') : ''),
        },
      ]
      return list
    },
    hasSelected() {
      return this.formData.detailList?.map((item) => item.id)
    },
    detailColumns() {
      const selectchangeItemList = []
      this.formData.changeItemList.forEach((item) => {
        if (this.priceInputScopedSlots.includes(item.itemCode)) {
          selectchangeItemList.push({
            title: `${item.itemName}(${this.$t('变更前')})`,
            dataIndex: `${item.itemCode}BeforeValue`,
            scopedSlots: {
              customRender: `${item.itemCode}BeforeValue`,
            },
          })
        } else {
          selectchangeItemList.push({
            title: `${item.itemName}(${this.$t('变更前')})`,
            dataIndex: `${item.itemCode}BeforeValue`,
            customRender: (text, record) => record[`${item.itemCode}BeforeName`] || text,
          })
        }
        selectchangeItemList.push({
          title: `${item.itemName}(${this.$t('变更后')})`,
          dataIndex: `${item.itemCode}AfterValue`,
          scopedSlots: {
            customRender: `${item.itemCode}AfterValue`,
          },
        })
      })
      const list = [
        {
          title: this.$t('资产编码'),
          dataIndex: 'assetCode',
        },
        // {
        //   title: this.$t('卡片编号'),
        //   dataIndex: 'cardNo',
        // },
        {
          title: this.$t('资产名称'),
          dataIndex: 'assetName',
        },
        {
          title: this.$t('会计政策'),
          dataIndex: 'accountingPolicyName',
        },
        ...selectchangeItemList,
        {
          title: this.$t('变更事由'),
          dataIndex: 'changeReason',
          scopedSlots: {
            customRender: 'changeReason',
          },
        },
      ]
      return list
    },
  },
  watch: {
    'formData.ownerBusinessUnitCode'(val, oldVal) {
      if (oldVal && val !== oldVal && !this.isReturn) {
        this.$confirm({
          title: this.$t('组织切换，将清除单据信息，是否确认切换？'),
          onOk: () => {
            this.formData.detailList = []
            this.formData.changeItemList = []
          },
          onCancel: () => {
            this.isReturn = true
            this.formData.ownerBusinessUnitName = undefined
            this.formData.ownerBusinessUnitCode = oldVal
          },
        })
      } else {
        this.isReturn = false
      }
    },
  },
  methods: {
    disabledDate(current) {
      return current && (current > moment(this.endDate) || current < moment(this.startDate))
    },
    async getCurrentAccountingPeriod() {
      let res = await http({
        url: api.getCurrentAccountingPeriod,
        data: {
          ownerBusinessUnitCode: this.formData.ownerBusinessUnitCode,
        },
      })
      this.startDate = res.result.start_date
      this.endDate = res.result.end_date
      this.formData.accountingDate = res.result.end_date
    },
    init() {
      this.isDetail = false
      this.isEdit = false
      if (!this.$route.query.id) {
        Object.assign(this.$data, this.$options.data())
        this.formData.accountingDate = moment()
          .subtract(1, 'months')
          .endOf('month')
          .format('YYYY-MM-DD') // 默认上个月月末
      } else {
        this.isDetail = true
        this.id = this.$route.query.id
        this.sn = this.$route.query.sn
        this.$nextTick(() => {
          this.$refs.OperationLogRef.queryList()
        })
      }
      this.getDetailInfo()
    },
    changeTabs(value) {
      document.querySelector(`#${value}`).scrollIntoView(true)
    },
    chooseAccountingDate(data) {
      this.formData.accountingDate = data.data
    },
    async getDetailInfo() {
      if (this.id) {
        this.loading = true
        await http({
          url: api.getAssetChangeDetail,
          data: {
            id: this.id,
          },
          success: (res) => {
            let result = convertKeysToCamelCase(res.result)
            this.formData = deepClone(result)
            if (result.orderStatus === 'DRAFT') {
              this.isEdit = true
            }
            this.setDetailItem()
            if (this.formData.attachmentList?.length > 0) {
              this.fileList = this.formData.attachmentList?.map((item) => {
                return {
                  uid: item.id,
                  name: item.fileName,
                  url: item.fileUrl,
                  status: 'done',
                }
              })
            }
          },
        })
        this.loading = false
      }
    },
    chooseBizDate(data) {
      this.formData.bizDate = data.data
    },
    async getChangeList() {
      this.tableLoading = true
      await http({
        url: api.assetChangeItemList,
        success: (res) => {
          this.dataSource = convertKeysToCamelCase(res.result)
        },
      })
      this.tableLoading = false
    },
    showChangeItem() {
      this.selectedRowKeys = this.formData.changeItemList.map((item) => item.id)
      this.selectedRows = []
      this.getChangeList()
      this.visible = true
    },
    handleOk() {
      // console.log('this.selectedRows: ', this.selectedRows)
      if (this.selectedRows.length === 0) {
        this.$message.warning(this.$t('请选择变更项目'))
        return
      }
      //开始使用日期不能与其它项目组合变更
      if (
        this.selectedRows.some((item) => item.itemCode === 'startUseDate') &&
        this.selectedRows.length > 1
      ) {
        this.$message.warning(this.$t('开始使用日期不能与其它项目组合变更'))
        return
      }
      //this.selectedRows里面的influenceDepreciation有不同值 报错
      if (
        this.selectedRows.some((item) => +item.influenceDepreciation === 1) &&
        this.selectedRows.some((item) => +item.influenceDepreciation === 0)
      ) {
        this.$message.warning(this.$t('变更类型不允许同时包含影响折旧和不影响折旧的变更项目'))
        return
      }
      this.formData.changeItemList = this.selectedRows.map((item) => {
        return {
          itemCode: item.itemCode,
          itemName: item.itemName,
          beforeChangeValue: '',
          beforeChangeName: '',
          afterChangeName: '',
          afterChangeValue: '',
          id: item.id,
        }
      })
      this.visible = false
      this.setDetailItem()
    },
    setDetailItem() {
      if (this.formData.detailList.length > 0 && this.formData.changeItemList.length > 0) {
        this.formData.detailList.forEach((item) => {
          this.formData.changeItemList.forEach((detail) => {
            let labelName = detail.itemCode
            let detailItem = item.detailItemList?.find((elem) => elem.changeItemCode === labelName)
            if (detailItem) {
              this.$set(item, `${labelName}BeforeValue`, detailItem.beforeChangeValue)
              this.$set(item, `${labelName}BeforeName`, detailItem.beforeChangeName)
              this.$set(item, `${labelName}AfterValue`, detailItem.afterChangeValue)
              this.$set(item, `${labelName}AfterName`, detailItem.afterChangeName)
              return
            }
            let value = item[labelName]
            this.$set(item, `${labelName}BeforeValue`, value)
            if (this.priceInputScopedSlots.includes(labelName)) {
              this.$set(item, `${labelName}AfterValue`, value)
            }
            if (labelName.includes('Code')) {
              let name = labelName.replace('Code', 'Name')
              this.$set(item, `${labelName}BeforeName`, item[name])
              // this.$set(item, `${labelName}AfterValue`, undefined)
              // this.$set(item, `${labelName}AfterName`, item[name])
            }
            if (['unit', 'storeLocation'].includes(labelName)) {
              this.$set(item, `${labelName}BeforeName`, item[labelName + 'Name'])
            }
          })
        })
        // console.log('this.formData.detailList: ', this.formData.detailList)
      }
    },
    handleCancel() {
      this.visible = false
    },
    onSelectChange(ids, rows) {
      this.selectedRows = rows
    },
    onSelectChange2(selectedRowKeys) {
      this.selectedRowKeys2 = selectedRowKeys
    },
    showAssetCardModal() {
      this.$refs.AssetCardModal.open()
    },
    deleteAssetCard() {
      if (this.selectedRowKeys2.length === 0) {
        this.$message.warning(this.$t(`至少选中一项`))
        return
      }
      this.formData.detailList = this.formData.detailList.filter(
        (item, index) => !this.selectedRowKeys2.includes(index)
      )
      this.selectedRowKeys2 = []
    },
    handleAssetCardOk(list) {
      let errList = []
      list.forEach((item) => {
        if (moment(item.startUseDate).isAfter(this.formData.accountingDate)) {
          errList.push(item.cardNo)
        }
      })
      if (errList.length > 0) {
        this.$error({
          title: this.$t(`错误`),
          content: this.$t(`资产卡片X是未来期间入账，不允许进行当前业务`, {
            X: errList.join('、'),
          }),
        })
        return
      }
      this.formData.detailList = [...this.formData.detailList, ...list]
      this.$refs.AssetCardModal.close()
      this.setDetailItem()
    },
    getBAList(list) {
      const baList = []
      list.forEach((item) => {
        baList.push(...[`${item}BeforeValue`, `${item}AfterValue`])
      })
      return baList
    },
    isPriceInput(name) {
      let bol = false
      this.priceInputScopedSlots.forEach((item) => {
        if (this.getBAList([item]).includes(name)) {
          bol = true
        }
      })
      return bol
    },
    backForm(back = 0) {
      goBack('assetChangeList', back)
    },
    verification() {
      let errMsg = ''
      this.formData.detailList.forEach((item, index) => {
        item.merchantCode = localStorage.getItem('merchantCode')
        item.assetCardId = item.id
        item.assetCardNo = item.assetCode
        const list = []
        this.formData.changeItemList.forEach((detail) => {
          const labelName = detail.itemCode
          if (
            item[`${labelName}AfterValue`] &&
            item[`${labelName}AfterValue`] !== item[`${labelName}BeforeValue`]
          ) {
            list.push({
              changeItemCode: labelName,
              changeItemName: detail.itemName,
              beforeChangeValue: item[`${labelName}BeforeValue`],
              beforeChangeName: item[`${labelName}BeforeName`],
              afterChangeValue: item[`${labelName}AfterValue`],
              afterChangeName: item[`${labelName}AfterName`],
            })
          }
          if (list.length === 0) {
            errMsg = this.$t('第X行未发生变更', { X: index + 1 })
          }
        })
        this.$set(item, 'detailItemList', list)
      })
      if (this.formData.detailList.length === 0) {
        errMsg = this.$t('请选择变更详情')
      }
      if (this.formData.changeItemList.length === 0) {
        errMsg = this.$t('请选择变更项目')
      }
      if (errMsg) {
        this.$message.warning(errMsg)
        return false
      }
      return true
    },
    onSubmit: debounce(async function (status) {
      if (!this.verification()) return
      let attachmentList = await this.$refs.AttachmentRef.changeUpload()
      const data = deepClone(this.formData)
      data.attachmentList = attachmentList
      data.orderStatus = status
      if (this.id) {
        data.id = this.id
      }
      if (status === 'DRAFT') {
        this.draftLoading = true
      } else {
        this.saveLoading = true
      }
      data.merchantCode = localStorage.getItem('merchantCode')

      await http({
        url: api.createAssetChange,
        data: {
          assetChange: data,
        },
        type: 'post',
        hasLoading: true,
        success: (res) => {
          if (res.success) {
            this.$success({
              title: this.$t(`成功`),
              content: this.$t(`保存成功`),
            })
            if (this.isDetail) {
              this.init()
            } else {
              this.backForm()
            }
          } else {
            this.$error({
              title: this.$t(`错误`),
              content: this.$t(`创建失败`),
            })
          }
        },
      })
      this.draftLoading = false
      this.saveLoading = false
    }),
  },
}
</script>
